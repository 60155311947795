.loan-selector {
    padding: 25px;
    width: 360px;
}

.loan-selector-header {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.09;
    padding-bottom: 24px;
}

.loan-selector-comment {
    font-size: 15px;
    line-height: 1.33;
    color: var(--color-dark-blue-grey);
}
.loan-selector-slider {
    padding-bottom: 24px;
}

.loan-selector-slider-text {
    display: flex;
    justify-content: space-between;
}

.loan-selector-slider-title {
    font-size: 14px;
    line-height: 1.71;
}

.loan-selector-slider-value {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
}