.client-info-form {
    padding: 45px 120px;
    width: 290px;
}

@media (max-width: 712px) {
    .client-info-form {
        padding: 25px;
        width: initial;
    }
}