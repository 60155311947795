.section-header {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
}

/*section welcome*/
.welcome {
    height: 800px;
    background-image: url(../../img/main-bg.png);
    background-position: center center;
    background-size: cover;
}

.welcome .col1 {
    padding-top: 312px;
}

.welcome .col2 {
    padding-top: 240px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.welcome .col2 .loan-selector {
    margin-bottom: 24px;
}

.welcome .col2 button {
    width: 410px;
    /*padding: 14px 0;*/
    /*font-size: 16px;*/
}

.welcome .text1 {
    width: 310px;
    padding: 25px;
    border-radius: 10px;
    font-size: 36px;
    font-weight: bold;
    color: var(--color-white);
    background-color: rgba(21, 26, 48, 0.4);
    line-height: 1.33;
    margin-bottom: 24px;
}

.welcome .text2 {
    width: 360px;
    font-size: 22px;
    color: var(--color-white);
    margin-bottom: 35px;
}

.store-links {
    display: flex;
}

.store-links .link {
    height: 50px;
    margin-right: 20px;
}

/*section steps*/
.steps {
    padding-bottom: 72px;
}

.cases {
    position: relative;
    top: -72px;

}

.case {
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05), 7px 7px 10px 0 rgba(1, 146, 209, 0.19);
    background-color: var(--color-white);
}

.case-header {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;
    padding: 32px 50px;
}

.case-img {
    text-align: center;
}

.case-limits {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;
    padding: 24px 0;
}

.case-footer {
    background-color: var(--color-blue-grey);
    padding: 0 24px 24px 24px;
}

.case-conditions {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
}

.case-condition {

}

.case-condition-title {
    font-size: 14px;
    line-height: 1.71;
    margin-right: 5px;
}

.case-condition-value {
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
}

.case-toolbar {

}

.steps-header {
    margin-bottom: 85px;
}

.step {
    width: 312px;
    margin: 0 auto;
}

.step-img {
    text-align: center;
}

.step-header {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.09;
    text-align: center;
    padding: 24px 0;
}

.step-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
}

/*section online-request*/
.online-request {
    background-color: var(--color-pale-grey);
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.online-request .client-info-form {
    position: relative;
    left: -30px;
    z-index: 1;
}

.online-request .loan-selector {
    position: relative;
    left: 60px;
    top: 72px;
    z-index: 2;
}

/*section online-request*/
.faq {
    padding-top: 72px;
    padding-bottom: 72px;
}

.faq-header {
    margin-bottom: 32px;
}

.faq-header2 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.45;
    margin-bottom: 32px;
}

.faq-title {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.45;
    color: var(--color-blue) !important;
}

.faq-title .MuiIconButton-label {
    color: var(--color-blue) !important;
}

/*section partners*/
.partners {
    background-color: var(--color-pale-grey);
    padding-top: 80px;
    padding-bottom: 150px;
}

.partners-header {
    margin-bottom: 100px;
}

.partner {
    width: 150px;
    text-align: center;
}

.partner img {
    height: 70px;
}

@media (max-width: 959px) {
    .online-request {
        flex-direction: column;
    }

    .online-request .client-info-form {
        position: relative;
        left: 0px;
    }

    .online-request .loan-selector {
        position: relative;
        left: 0px;
        top: 0px;
        margin-bottom: 20px;
        width: 480px;
    }
}
@media (max-width: 712px) {
    .welcome .col2 {
        display: initial;
    }
    .welcome .col2 .loan-selector {
        width: initial;

    }
    .welcome .col2 button {
        width: 100%;
    }

    .online-request .loan-selector {
        width: initial;
    }
}