.submenu {

}

.submenu li.success:before {
    content: "";
    background-image: url(../../img/success.svg);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 11px;
    right: 40px;
}

.submenu li:after {
    content: "";
    background-image: url(../../img/22-icon-chevron-right.svg);
    width: 10px;
    height: 14px;
    position: absolute;
    top: 15px;
    right: 22px;
}

.submenu li.submenu-active:after {
    background-image: url(../../img/22-icon-chevron-left.svg);
}