.z-buyouts-list {

}

.z-buyouts-group {
    margin-bottom: 15px;
    overflow: hidden;
}
.z-buyouts-group-date {
    margin: 0px 15px 5px 15px;
}
.z-buyouts-items {
    position: relative;
    min-height: 300px;
}
.z-buyouts-item {
    margin: 0px 15px 15px 15px;
    display: flex;
    overflow: hidden;
}
.z-buyouts-empty-item {
    margin: 0px 15px 15px 15px;
    display: flex;
    justify-content: center;
    padding: 45px;
    /*width: 300px;*/
}
.z-buyouts-empty-item p {
    /*color: var(--color-wild);*/
}

.z-buyouts-item-image {
    height: 150px;
    width: 150px;
    display: block;
    object-fit: cover;
    object-position: center;
}
.z-buyouts-item-image-sm {
    height: 100px;
    width: 100px;
    display: block;
    object-fit: cover;
    object-position: center;
}

.z-buyouts-item-content {
    flex: 1;
    display: flex;
}

.z-buyouts-item-info {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
}

.z-buyouts-item-info-header {
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.z-buyouts-item-delivery {
    width: 350px;
    /*flex: 1;*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
}

.z-buyouts-item-action {
    width: 200px;
    /*flex: 1;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*padding: 15px;*/
}

.z-buyouts-item-payment-qr-pay {

}

.z-buyouts-item-payment-qr-receive {
    height: 200px;
}

.z-buyouts-item-delivery-workTime {
    /*max-width: 250px;*/
    /*text-overflow: ellipsis;*/
    /*white-space: nowrap;*/
    /*overflow: hidden;*/
}

.z-buyouts-item-delivery-address {
    /*max-width: 200px;*/
    /*text-overflow: ellipsis;*/
    /*white-space: nowrap;*/
    /*overflow: hidden;*/
}

.z-buyouts-toolbar {
    margin: 0px 15px 15px 15px;
    display: flex;
    justify-content: space-between;
}

.z-buyouts-toolbar-top {
    height: 60px;
    align-items: center;
    margin: 0px;
    padding: 0px 15px;
}
.z-buyouts-filter {

}
.z-buyouts-filter-count {
    background-color: var(--color-light-wild);
    color: white;
    border-radius: 10rem;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    padding: 2px;
    box-sizing: content-box;
    margin-left: 3px;
}
.z-buyouts-filter-count.grey {
    background-color: var(--color-light-grey);
    /*color: white;*/
}

.z-buyouts-toolbar .z-buyouts-filter {
    margin-right: 10px;
}

/*Comments*/
.z-buyout-comment-rate-block {
    display: flex;
    justify-content: center;
}

.z-buyout-comment-rate-star {

}


.z-new-buyout {
    margin: 0px 15px 15px 15px;
    padding: 15px;
    /*display: flex;*/
    overflow: hidden;
}

.z-new-buyout-row {
    margin-bottom: 15px;
}


.post-select {
    position: fixed;
    left: 200px;
    right: 200px;
    top: -10000px;
    bottom: 100px;
    z-index: 10;
    outline: 0;
    -webkit-transition: right .3s ease-in-out;
    -moz-transition:    right .3s ease-in-out;
    -o-transition:      right .3s ease-in-out;
    -ms-transition:     right .3s ease-in-out;
    transition:         right .3s ease-in-out;
    background-color: var(--color-white);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transition: top .3s ease-in-out;
    -moz-transition:    top .3s ease-in-out;
    -o-transition:      top .3s ease-in-out;
    -ms-transition:     top .3s ease-in-out;
    transition:         top .3s ease-in-out;
    display: flex;
    align-items: stretch;
}
.post-select .post-select-list-outer {
    width: 30%;
    /*todo remove for list to appear*/
    display: none;
}
.post-select .post-select-map {
    flex: 1;
}
.post-select.open {
    top: 100px;
}

.side-menu ul {
    list-style: none;
    padding: 0px;
}

.side-menu li {
    text-align: right;
    padding: 10px 0;

}
.side-menu li a {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    /*color: var(--color-dark);*/
}


@media (max-width: 959px) {
    .z-buyouts-item-content {
        flex-direction: column;
    }

    .z-buyouts-item-info {
        width: 150px;
    }

    .z-buyouts-item-image,
    .z-buyouts-item-image-sm {
        display: none;
    }

    .z-buyouts-item-info-header {
        max-width: 200px;
    }
}