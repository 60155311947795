.request-page {
    padding-top: 168px;
    padding-bottom: 45px;
    background-color: var(--color-pale-grey);
}

.request-page.request1 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.request-page.request1 .client-info-form {
    position: relative;
    left: -30px;
    z-index: 1;
}

.request-page.request1 .loan-selector {
    position: relative;
    left: 60px;
    top: 72px;
    z-index: 2;
}
.request-page.request2 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.request-page.request2 .login {
    position: relative;
    left: -30px;
    z-index: 1;
}

.request-page.request2 .submenu {
    position: relative;
    left: 60px;
    top: 72px;
    z-index: 2;
    width: 360px;
}
.request-page.request3 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.request-page.request3 .login {
    position: relative;
    left: -30px;
    z-index: 1;
}

.request-page.request3 .submenu {
    position: relative;
    left: 60px;
    top: 72px;
    z-index: 2;
    width: 360px;
}
.request-page.request4 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.request-page.request4 .login {
    position: relative;
    left: -30px;
    z-index: 1;
}

.request-page.request4 .submenu {
    position: relative;
    left: 60px;
    top: 72px;
    z-index: 2;
    width: 360px;
}

.request-page.request6 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.request-page.request6 .login {
    position: relative;
    left: -30px;
    z-index: 1;
}


.request-step-description {
    padding-top: 30px;
    width: 300px;
}
.request-step-description-header {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    margin-bottom: 15px;
}
.request-step-progress {
    margin-bottom: 14px;
}
.request-step-description-text {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    margin-bottom: 24px;
}
.request-step-description2-text {
    font-size: 13px;
    line-height: 1.38;
    margin-bottom: 24px;
    color: var(--color-dark-blue-grey);
}
.request-step-description3-text {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    margin-bottom: 14px;
}
.request-step-description-link {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 24px;
}

.file-info-form-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
}
.file-info-form-preview .inner {
    max-width: 100%;
    position: relative;
}
.file-info-form-preview .inner .ok-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    margin-left: -16px;
    margin-top: -16px;
}
.file-info-form-preview .inner .remove-icon {
    position: absolute;
    top: -9px;
    right: -9px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.file-info-form-preview img {
    max-width: 100%;
    max-height: 130px
}
.file-info-form-preview .file-icon {
    height: 100px

}

