.app > section.personal-page {
    margin-top: 168px;
    margin-bottom: 45px;
    background-color: var(--color-pale-grey);
    height: 700px;
    padding: 50px 25px 50px 370px;
    position: relative;
}

.personal-menu {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 170px;
    padding: 50px;
    background-color: var(--color-white);
}

.personal-menu-item {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: -0.4px;
    color: var(--color-dark-grey);
    cursor: pointer;
    width: 120px;
    height: 48px;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05), 7px 7px 10px 0 rgba(1, 146, 209, 0.19);
    border: solid 1px rgba(0, 159, 227, 0.1);
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    background-position: -8px 24px;
}

.personal-menu-item.menu-active {
    color: var(--color-white);
    background-color: var(--color-blue);
}

.personal-menu-item.menu-loans {
    background-image: url(../../img/icon_Loan.svg);
    background-repeat: no-repeat;
}
.personal-menu-item.menu-loans.menu-active {
    background-image: url(../../img/icon_Loan_active.svg);
}

.personal-menu-item.menu-profile {
    background-image: url(../../img/icon_Profile.svg);
    background-repeat: no-repeat;
}
.personal-menu-item.menu-profile.menu-active {
    background-image: url(../../img/icon_Profile_active.svg);
}

.personal-menu-item.menu-settings {
    background-image: url(../../img/icon_Settings.svg);
    background-repeat: no-repeat;
}
.personal-menu-item.menu-settings.menu-active {
    background-image: url(../../img/icon_Settings_active.svg);
}

.personal-profile-menu-item {
    position: relative;
    left: -14px;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    cursor: pointer;
    width: 135px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 5px 35px 5px 14px;
    background-position: -8px 24px;
    word-break: break-word;
}

.personal-profile-menu-item.menu-active {
    background-color: rgba(0, 159, 227, 0.16);
}

.personal-profile-menu-item:after {
    content: "";
    background-image: url(../../img/22-icon-chevron-right.svg);
    width: 10px;
    height: 14px;
    position: absolute;
    top: 15px;
    right: 22px;
}

.personal-profile-menu-item.menu-active:after {
    background-image: url(../../img/22-icon-chevron-left.svg);
}


.personal-page .col1 {
    padding: 0 70px;
}

.personal-page .col1 h1 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.45;
    margin-top: 0px;
    margin-bottom: 16px;
}
.personal-page .col1 .loan-selector {
    margin-bottom: 24px;
    width: initial;
}
.personal-page .col1 .loan-info {
    margin-bottom: 24px;
}

.loan-info {
    padding: 25px;
    position: relative;
}
.loans-goto-toolbar {
    display: flex;
    justify-content: space-between;
    height: 31px;
    align-items: center;
}

.loan-goto-btn {
    margin: 0 2px 0 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.85;
    color: var(--color-dark-blue-grey)
}