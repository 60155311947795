.z-drawer {

}

.z-app-bar {

}

.z-drawer {
}

.z-nav-link {
    transition: background 0.15s ease, color 0.15s ease;
}

.z-nav-link .z-list-item {
    border-radius: 0.5rem;
}

.z-nav-link.z-nav-link-short .z-list-item {

}

.z-nav-link .z-list-item-icon-outer {
    text-align: center;
}

.z-nav-link .z-list-item-icon {
    color: var(--color-dark-grey);
    font-size: 20px;
    padding: 10px 0px;
}

.z-nav-link .z-list-item-text {
    color: var(--color-dark-grey);
    font-size: 17px;
    font-weight: 400;
    padding-left: 17px;
}

.z-nav-link.active .z-list-item {
    background-color: var(--color-light-wild);
}

.z-nav-link.active .z-list-item-icon {
    color: var(--color-white);
}

.z-nav-link.active .z-list-item-text {
    color: var(--color-white);
}

/* Profile Fragment */

.z-profile {
    padding: 15px;
    margin-bottom: 20px;
    transition: opacity 0.2s ease, height 0.2s ease, margin-bottom 0.2s ease, padding 0.2s ease;
    height: 160px;
}

.z-drawer-closed .z-profile {
    opacity: 0;
    height: 0px;
    margin-bottom: 0px;
    padding: 0px;
}

.z-profile .z-profile-btn {
    padding: 10px;
    flex: 0;
}

.z-profile-login {
    margin-top: 6px;
    margin-bottom: 20px;
}

.z-profile-login-value {
    font-size: 18px;
    font-weight: 500;
}

.z-profile-balance {
    color: var(--color-dark-grey);
}

.z-profile-balance-title {

}

.z-profile-balance-value {
    font-weight: 500;
}

/* Balance Modal */

.z-balance-modal {
    width: 400px;
    left: calc(50vw - 200px);
}
.z-balance-modal.open {

}

.z-balance-modal-header {

}

.z-balance-modal-row {
    margin-bottom: 1rem;
}

/* Pay Modal */

.z-pay-modal {
    width: 500px;
    left: calc(50vw - 250px);
}
.z-receive-modal {
    width: 800px;
    left: calc(50vw - 400px);
}

/*.z-drawer-app-bar-shift {*/
/*    margin-left: 250px;*/
/*}*/

/*

root: {
    display: 'flex',
},
appBar: {
zIndex: theme.zIndex.drawer + 1,
transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
}),
},
appBarShift: {
marginLeft: drawerWidth,
width: `calc(100% - ${drawerWidth}px)`,
transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
}),
},
menuButton: {
    marginRight: 36,
},
hide: {
    display: 'none',
},
drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
},
drawerOpen: {
width: drawerWidth,
transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
}),
},
drawerClose: {
transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
}),
overflowX: 'hidden',
width: theme.spacing(7) + 1,
[theme.breakpoints.up('sm')]: {
                                width: theme.spacing(9) + 1,
                            },
},
toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
},
content: {
    flexGrow: 1,
    padding: theme.spacing(3),
},

/*
 */