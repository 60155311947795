.z-widget {

}

/*Simple Value*/
.z-simple-value-widget {
    box-sizing: content-box;
    padding: 20px;
    display: flex;
    width: 170px;
    height: 130px;
}

.z-simple-value-widget-icon {
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    padding: 15px 20px 15px 15px;
    width: 45px;
}

.z-simple-value-widget-title {
    /*box-sizing: content-box;*/
    height: 45%;
    line-height: 1;
    display: flex;
    align-items: flex-end;
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 300;
}

.z-simple-value-widget-value {
    /*box-sizing: content-box;*/
    height: 55%;
    line-height: 1;
    display: flex;
    align-items: flex-start;
    padding: 0px 0px;
    font-size: 35px;
    color: var(--color-dark);
    font-weight: 400;
}

.z-dashboard-toolbar-product {
    width: 250px;
    height: 50px;
}

/*Bar Chart*/
.z-bar-chart-widget {
    height: calc(340px + 1rem);
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.z-bar-chart-widget-chart {
    flex: 1;
}

/*Simple List*/
.z-simple-list-widget {
    height: calc(340px + 1rem);
    padding: 15px;
    display: flex;
    flex-direction: column;
}
.z-simple-list-widget-table {
    flex: 1;
    overflow-y: scroll;
}


/*Chart*/
.z-chart-widget {
    height: 500px;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.z-chart-widget-chart {
    flex: 1;
}