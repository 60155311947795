.app > header,
.app > section,
.app > div > section,
.app > .footer {
    padding-left: 25px;
    padding-right: 25px;
}

.app-loader {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

/*app header*/
.app-header {
    position: absolute;
    height: 72px;
    top: 24px;
    left: 0px;
    right: 0px;
}

.app-header-item {
    display: flex;
    flex-direction: column;
    height: 72px;
    align-items: flex-end;
    justify-content: center;
    margin-right: 24px;
}

.app-header-item:last-child {
    margin-right: 0;
}

.app-header-link {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--color-dark);
}

.app-header-logo {
    margin-right: 77px;
}

.header-phone {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    display: flex;
    margin-bottom: 2px;
    padding-top: 14px;
}

.header-phone-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-align: right;
}

.header-lang {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.14;
    display: flex;
    color: var(--color-dark);
}

.header-lang img,
.header-profile img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.lang-menu-item img {
    margin-right: 5px;
}

.header-profile {
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    display: flex;
    color: var(--color-dark);

}

/*footer*/
.footer {
    padding-top: 48px;
    padding-bottom: 48px;
}

.footer-param-title {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.85;
    color: var(--color-dark-blue-grey);
    padding-top: 24px;
}

.footer-param-value {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
}

.footer-link1 {
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 24px;
    color: var(--color-dark);
}

.footer-link2 {
    display: block;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 24px;
    color: var(--color-dark-blue-grey);
}

.footer-right-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.footer-phone {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    display: flex;
    margin-bottom: 16px;
}

.side-menu {
    position: fixed;
    right: -250px;
    top: 0px;
    height: 100vh;
    width: 200px;
    z-index: 10;
    -webkit-transition: right .3s ease-in-out;
    -moz-transition: right .3s ease-in-out;
    -o-transition: right .3s ease-in-out;
    -ms-transition: right .3s ease-in-out;
    transition: right .3s ease-in-out;
    background-color: var(--color-white);
    padding: 25px;
    -webkit-transform: translate3d(0, 0, 0);
}

.side-menu.open {
    right: 0px;
}

.side-menu ul {
    list-style: none;
    padding: 0px;
}

.side-menu li {
    text-align: right;
    padding: 10px 0;

}

.side-menu li a {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    /*color: var(--color-dark);*/
}

.z-page {
    overflow: hidden;
}

.z-box {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
    background-color: var(--color-white);
}

.z-box-dark {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
    background-color: rgba(0, 0, 0, 0.03);
}

.z-modal {
    position: fixed;
    left: 15vw;
    top: -200%;
    /*height: 80vh;*/
    width: 70vw;
    z-index: 10;
    -webkit-transition: top .3s ease-in-out;
    -moz-transition: top .3s ease-in-out;
    -o-transition: top .3s ease-in-out;
    -ms-transition: top .3s ease-in-out;
    transition: top .3s ease-in-out;
    background-color: var(--color-white);
    padding: 25px;
    -webkit-transform: translate3d(0, 0, 0);
    border-radius: 0.5rem;
}

.z-modal.open {
    top: 15vh;
}

.z-page-header {
    font-size: 30px;
    font-weight: 600;
    color: var(--color-dark);
    margin-bottom: 15px;
}
.z-page-header2 {
    font-size: 22px;
    font-weight: 600;
    color: var(--color-dark);
    margin-bottom: 15px;
}

.loading-btn-container {
    height: 60px;
}

@media (max-width: 1399px) {
    .app-header-phone {
        display: none;
    }
}

@media (max-width: 1199px) {
    .header-profile img {
        width: 22px;
        height: 22px;
    }

    .header-profile-text,
    .header-lang-text {
        display: none;
    }
}

@media (max-width: 959px) {
    .app-header-logo {
        margin-right: initial;
    }
}