.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 1500;
}
.loader-spinner {
     width: 100px;
     height: 100px;
     max-width: 100%;
     max-height: 100%;
     -webkit-animation: rotating 0.5s linear infinite;
     -moz-animation: rotating 0.5s linear infinite;
     -ms-animation: rotating 0.5s linear infinite;
     -o-animation: rotating 0.5s linear infinite;
     animation: rotating 0.5s linear infinite;
 }