:root {
    --color-white: white;
    --color-black: black;
    --color-wild: #7a0587;
    --color-light-wild: #ac08be;
    --color-green: #498839;
    --color-light-green: #72d959;
    --color-blue: #2a2b87;
    --color-light-blue: #414bd9;
    --color-bg-grey: #FBFBFB;
    --color-dark-grey: #4d4d4d;
    --color-grey: #878787;
    --color-light-grey: #d9d9d9;
    --color-dark: #141826;


    --color-azure: #01acf5;
    --color-blue-grey: rgba(143, 155, 179, 0.08);
    --color-pale-grey: #edf1f7;
    --color-dark-blue-grey: #8f9bb3;
    --color-red: #f45b54;
    --drawer-width: 250px;

}

body {
    /*padding: 0;*/
    /*margin: 0;*/
    /*font-family: 'Open Sans', sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-dark-grey);
}

/*a {*/
/*    color: var(--color-wild);*/
/*    text-decoration: none;*/
/*    cursor: pointer;*/
/*}*/

.d-flex {
    display: flex;
}

.hidden {
    display: none !important;
}

@media (min-width: 960px) {
    .only-mobile {
        display: none !important;
    }
}
@media (max-width: 959px) {
    .only-desktop {
        display: none !important;
    }
}