.feedback-form {
    padding: 45px 120px;
    width: 290px;
}

.feedback-form-header {
    margin: 0 auto 32px auto;
    padding: 0 45px;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;
}

.feedback-form-subheader {
    margin: 0 auto 32px auto;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
}

.feedback-form-row {
    margin-bottom: 32px;
}

.feedback-form-text {
    font-size: 11px;
    line-height: 1.27;
    color: var(--color-dark-blue-grey);
}
@media (max-width: 719px) {
    .feedback-form {
        padding: 25px;
        width: initial;
    }
}