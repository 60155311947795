.info-page {

}

.info-page .submenu {
    margin-bottom: 24px;
}

.info-page .loan-selector {
    margin-bottom: 24px;
    width: initial;
}

.info-page .col2 > div {
    padding: 20px 48px;
}

.info-page .col2 .info-action-comment {
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    width: 440px;
    margin: 0 auto;
    margin-bottom: 25px;
}

.info-page .col2 .info-action {
    width: 360px;
    margin: 0 auto;
}
.info-page .col2 h1 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    text-align: center;
}

.info-page .pay-info-btn {
    padding: 25px 25px 16px 25px;
    border: solid 1px #009fe3;
    cursor: pointer;
    max-width: 150px;
    margin: 0 auto;
}
.info-page .info-pay-options {
    margin-bottom: 25px;
}

.info-page .pay-info-btn:hover,
.info-page .pay-info-btn:active,
.info-page .pay-info-btn.active {
    border: solid 2px #009fe3;
}

.info-page .pay-info-btn .pay-info-btn-icon {
    text-align: center;
    margin-bottom: 16px;
}

.info-page .pay-info-btn .pay-info-btn-icon img {
    height: 50px;
}

.info-page .pay-info-btn .pay-info-btn-text {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}