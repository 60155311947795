/*common login*/
.login {
    padding: 45px 120px;
    width: 530px;
    position: relative;
}

.login-header {
    margin: 0 auto 35px auto;
    padding: 0 28px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    text-align: center;
}

.login-header2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
}

.login-header3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    color: var(--color-red);
}

.login-row {
    margin-bottom: 32px;
}

.login-flex {
    display: flex;
    justify-content: space-between;
}

.login-field-comment {
    font-size: 12px;
    line-height: 1.33;
    color: var(--color-dark-blue-grey);
}

.login-field-error {
    font-size: 12px;
    line-height: 1.33;
    color: var(--color-red);
    margin-bottom: 5px;
}

.login-form-result {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.33;
    padding: 20px 40px;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    left: 0px;
    top: -40px;
    right: 0px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-form-result svg {
    margin-right: 10px;
}

.login-form-result.success {
    background-color: var(--color-green);
}

.login-form-result.error {
    background-color: var(--color-red);
}

.login-text {
    font-size: 15px;
    line-height: 1.71;
}

.login-bold-text {
    font-size: 16px;
    line-height: 1.71;
    font-weight: bold;
}

.login-link {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
}

.login-link2 {
    font-size: 12px;
    line-height: 1.33;
    text-align: right;
    position: relative;
    /*bottom: -14px;*/
}

.login-page {
    padding-top: 168px;
    padding-bottom: 45px;
    display: flex;
    justify-content: center;
    /*background-color: var(--color-pale-grey);*/
}

.login-action-container {
    height: 60px;
}